<template>
  <!--
    Used to look up devices from the device-service.
  -->
  <Portlet
    :title="$t('deviceSearchComponent.searchDevices')"
    class="deviceSearch"
    icon="search"
  >
    <LoadingPlaceholder v-if="preloading" />

    <template v-else>
      <div class="row mb-2">
        <div class="col-12 col-lg-3 col-xl-3 mb-3">
          <div class="input-group">
            <div class="input-prepend">
              <label class="mr-3 mt-2 mb-0">{{ $t('name') }}</label>
            </div>
            <input
              v-model="searchtext"
              v-focus
              class="form-control rounded"
              @keyup.enter="searchDevices()"
            >
          </div>
        </div>

        <div class="col-12 col-lg-3 col-xl-3 mb-3">
          <div class="input-group">
            <div class="input-prepend">
              <label class="mr-3 mt-2 mb-0">{{ $t('configuration') }}</label>
            </div>
            <input
              v-model="configuration"
              class="form-control rounded"
              @keyup.enter="searchDevices()"
            >
          </div>
        </div>

        <div class="col-12 col-lg-3 col-xl-3 mb-3">
          <div class="input-group">
            <div class="input-prepend">
              <label class="mr-3 mt-2 mb-0">{{ $t('tags') }}</label>
            </div>
            <Multiselect
              ref="centralDeviceManagementMultiselect"
              v-model="searchTags"
              :options="tags"
              :taggable="false"
              :show-labels="true"
              :searchable="true"
              :close-on-select="true"
              :hide-selected="true"
              :multiple="true"
              class="m-input m-input--air alt-pointer d-inline-block form-control shadow-none border-0 m-0 p-0"
              deselect-label="Click to deselect"
              select-label="Click to select"
              placeholder="select tags to filter by"
            >
              <template
                slot="option"
                slot-scope="{option}"
              >
                <span>{{ option }}</span>
              </template>
              <template
                slot="tag"
                slot-scope="{option}"
              >
                <span
                  class="m-badge m-badge--primary m-badge--wide"
                  @click="deselectOption(option)"
                >{{ option }} &times;</span>
              </template>
            </Multiselect>
          </div>
        </div>

        <div class="col-12 col-lg-1 col-xl-1 mb-3">
          <div class="input-group">
            <div class="input-prepend">
              <label class="mr-3 mt-2 mb-0">VPN</label>
            </div>
            <input
              v-model="vpnConnection"
              type="checkbox"
              class="rounded"
              style="margin-top: 10px;"
              @keyup.enter="searchDevices()"
            >
          </div>
        </div>

        <div class="col-12 col-lg-2">
          <button
            class="btn btn-primary btn-block d-block d-sm-none d-lg-none d-xl-block"
            @click="searchDevices()"
          >
            <font-awesome-icon
              class="mr-2"
              icon="search"
            />
            <span>{{ $t('search') }}</span>
          </button>
          <button
            class="btn btn-primary float-right d-none d-sm-block d-lg-block d-xl-none"
            @click="searchDevices()"
          >
            <font-awesome-icon
              class="mr-2"
              icon="search"
            />
            <span>{{ $t('search') }}</span>
          </button>
          <div class="clearfix" />
        </div>
      </div>
    </template>

    <LoadingPlaceholder
      v-if="loading"
      class="mt-4"
    />

    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(devices)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(devices)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="nameLink"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <router-link
              v-if="props.dataItem.installationId"
              :to="`/installation/${ props.dataItem.installationId }/devices/device/${ props.dataItem.uuid }/c`"
              class="alt-primary-color"
            >
              <span>{{ kgm_getNestedValue(props.field, props.dataItem) }}</span>
            </router-link>
            <router-link
              v-else
              :to="`/centraldevicemanagement/unconfigured-view/${ props.dataItem.uuid }/null`"
              target="_blank"
              class="alt-primary-color"
            >
              <span>{{ kgm_getNestedValue(props.field, props.dataItem) }}</span>
            </router-link>
          </td>
        </template>
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="vpnFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="vpnFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a VPN-Address"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('vpnFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="deviceTypeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="deviceTypeSelect"
            :key="`deviceTypeSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(deviceType, key) in deviceTypes"
              :key="`deviceSearch-DeviceTypeFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, deviceType, 'deviceTypeSelect')"
            >
              {{ deviceType }}
            </option>
          </select>
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('deviceTypeSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="applicationTypeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="applicationTypeSelect"
            :key="`applicationTypeSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(applicationType, key) in applicationTypes"
              :key="`deviceSearch-applicationTypeFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, applicationType, 'applicationTypeSelect')"
            >
              {{ applicationType }}
            </option>
          </select>
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('applicationTypeSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="installedVersionFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="installedVersionSelect"
            :key="`installedVersionSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(installedVersion, key) in installedVersions"
              :key="`deviceSearch-installedVersionFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, installedVersion, 'installedVersionSelect')"
            >
              {{ installedVersion }}
            </option>
          </select>
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('installedVersionSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="tagsFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="tagsSelect"
            :key="`installedVersionSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(tag, key) in tags"
              :key="`deviceSearch-tagsFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, tag, 'tagsSelect')"
            >
              {{ tag }}
            </option>
          </select>
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('tagsSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
      </Grid>
    </template>
  </Portlet>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: 'DeviceSearch',
  components: {
    Multiselect: () => import('vue-multiselect'),
  },
  mixins: [
    kendoGridMixin
  ],
  data () {
    return {
      loading: false,
      preloading: true,
      configuration: null,
      searchtext: "",
      vpnConnection: false,
      devices: null,
      cancelSource: null,
      tags: null,
      searchTags: [],
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: "name",
            operator: "contains",
            value: ""
          },
          {
            field: "vpnIpAddress",
            operator: "contains",
            value: ""
          }
        ]
      },
      kgm_sort: [
        {
          "field": "name",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: this.$t('deviceSearchComponent.name'),
          filterCell: "nameFilter",
          cell: "nameLink"
        },
        {
          field: 'deviceType',
          filterable: true,
          filter: 'text',
          title: this.$t('deviceSearchComponent.deviceType'),
          filterCell: "deviceTypeFilter",
          width: '220px',
          hideOn: ['smDown']
        },
        {
          field: 'applicationType',
          filterable: true,
          filter: 'text',
          title: this.$t('deviceSearchComponent.applicationType'),
          filterCell: "applicationTypeFilter",
          width: '160px',
          hideOn: ['mdDown']
        },
        {
          field: 'installedVersion',
          filterable: true,
          filter: 'text',
          title: this.$t('deviceSearchComponent.installedVersion'),
          filterCell: "installedVersionFilter",
          width: '220px',
          hideOn: ['xlDown']
        },
        {
          field: 'vpnIpAddress',
          filterable: true,
          filter: 'text',
          title: this.$t('deviceSearchComponent.vpnIpAddress'),
          width: '220px',
          filterCell: "vpnFilter"
        },
        {
          field: 'tags',
          filterable: true,
          filter: 'text',
          title: this.$t('deviceSearchComponent.tags'),
          filterCell: "tagsFilter",
          width: '220px',
          hideOn: ['lgDown']
        }
      ]
    }
  },
  computed: {
    installedVersions: function () {
      if (!this.devices) {
        return [ "" ];
      }
      //Distinct (include every entry only once)
      let uniqueVersionList = Array.from(new Set(this.devices.map(o=>o.installedVersion)));
      //Remove null values
      uniqueVersionList = uniqueVersionList.filter(function (e) {return e});
      return uniqueVersionList.sort();
    },
    applicationTypes: function () {
      if (!this.devices) {
        return [ "" ];
      }
      let uniqueApplicationTypeList = Array.from(new Set(this.devices.map(o=>o.applicationType)));
      uniqueApplicationTypeList = uniqueApplicationTypeList.filter(function (e) {return e});
      return uniqueApplicationTypeList.sort();
    },
    deviceTypes: function () {
      if (!this.devices) {
        return [ "" ];
      }
      let uniqueDeviceTypeList = Array.from(new Set(this.devices.map(o=>o.deviceType)));
      uniqueDeviceTypeList = uniqueDeviceTypeList.filter(function (e) {return e});
      return uniqueDeviceTypeList.sort();
    }
  },
  created () {
    this.getDeviceTags();
    if(this.$route.params.configuration) {
        this.configuration = this.$route.params.configuration;
        this.searchDevices();
    }
    if(this.$route.params.deviceName) {
        this.searchtext = this.$route.params.deviceName;
        this.searchDevices();
    }
  },
  beforeDestroy () {
    this.cancelSearch();
  },
  methods: {
    deselectOption (option) {
      if (option == null) {
        this.searchTags = [];
        return;
      }
      let index = this.searchTags.indexOf(option);
      if (index > 0) {
        this.searchTags = [];
        return;
      }
      this.searchTags.splice(index, 1);
    },
    getDeviceTags () {
      this.axios.get(`/CentralDeviceManagement/GetDeviceTags`)
        .then((response) => {
          if (response) {
            this.tags = response.data;
          }
        })
        .finally(() => {
          this.preloading = false;
        });
    },
    searchDevices () {
      this.loading = true;
      this.cancelSearch();
      this.cancelSource = this.axios.CancelToken.source();

      var request = {
        params: {
          search: this.searchtext,
          tags: this.searchTags,
          configuration: this.configuration,
          vpn: this.vpnConnection
        },
        cancelToken: this.cancelSource.token
      }

      this.axios.get(`/CentralDeviceManagement/Search`, request)
        .then((response) => {
          if (response == null) {
            this.devices = null;
            return;
          }
          if (response.status == 204) {
            this.devices = null;
            return;
          }
          if (response.data == null) {
            this.devices = null;
            return;
          }
          if (response.data.length == 0) {
            this.devices = null;
            return;
          }
          this.devices = response.data;
          this.devices.forEach(device => {
            if (device.tags) {
              device.tags = this.computedTags(device.tags)
            }
          });
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    computedTags (tags) {
        let computedTags = ``;
        let tagsLength = tags.length;
        tags.forEach((tag, index) => {
          if (index + 1 >= tagsLength) {
            computedTags += `${ tag }`;
          } else {
            computedTags += `${ tag }, `;
          }
        });
        return computedTags;
    },
    cancelSearch () {
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.deviceSearch .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>
